import 'src/resources/styles/preloading.scss'
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import packageJson from '../../package.json'

// eslint-disable-next-line no-console
console.info(`${packageJson?.name} %c${packageJson?.version}`, 'color: #07b4c0')

import('./app')
